.aboutSection{
    padding: 3rem 12rem;
}
.aboutHeading{
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 3rem;
    text-align: center;
}
.description{
    text-align: center;
    line-height: 2rem;
}
@media (max-width: 768px) {
    .aboutSection{
        padding: 3rem 3rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
  
  }