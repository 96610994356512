.form-text {
  background: #f5f6fa 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  border: none !important;
}
.form-text:hover {
  border: none !important;
}
.nav-profile-image{
  position: relative;
    height: 10rem;
  width: 10rem;
}
.profile-img {
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  height: 100%;

}
.overlay {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* semi-transparent black overlay */
  opacity: 0; /* initially hidden */
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.nav-profile-image:hover .overlay {
  opacity: 1; /* show overlay on hover */
}