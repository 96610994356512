@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.btn-primary {
  background: linear-gradient(31deg, #6f35c7 0%, #9228cd 100%) 0% 0% no-repeat
    padding-box !important;
  border: none !important;
  border-radius: 12px !important;
  width: 141px;
  padding: 10px 0;
  color: #FFFFFF;
}

.btn-outline-primary, .btn-outline-primary-border {
  border: none !important;
  border-radius: 12px !important;
  width: 175px;
  padding: 9px 0;
  color: #6f35c7;
  border: 1.89px solid #6f35c7 !important;
}

.btn-small{
  width: 141px !important;
}

.btn-outline-primary:hover{
  background: linear-gradient(31deg, #6f35c7 0%, #9228cd 100%) 0% 0% no-repeat
  padding-box !important;

}
.MuiOutlinedInput-notchedOutline{
  border: none !important;
}


.PhoneInput {
  height: 3.5rem !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 1rem !important;
}

.PhoneInputInput{
  margin-bottom: 0rem;
  border: none !important;
  background: #e7e8e9 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
  outline: none !important;
}
.PhoneInputInput:focus{
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.PhoneInput:focus-within{
  border: none !important;
}
.form-control{
  height: 3.5rem !important;
  border: none !important;
  /* border: 1.567px solid #c4c4c4;
  border-radius: 4px !important; */
  font-size: 1rem !important;
  box-shadow: none !important;
  outline: none !important;
  background: #e7e8e9 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
}
.btn {
  box-shadow: none !important;
  outline: none !important;
}
.form-control:focus,
.btn:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.custom-card-border {
  border-left: 1px solid #e1e4ee;
  border-right: 1px solid #e1e4ee;
}

.cards {
  display: flex;
  justify-content: space-around;
  background-color: whitesmoke;
  padding: 1.2rem;
  border-radius: 10px;
  margin-bottom: 10px;
}
.tabbox {
  /* background: rgba(203, 195, 227, 0.5) !important; */
  border-radius: 20px !important;
  margin-right: 20px;
  width: 33% !important;
}
.hovereffect {
  padding: 5px !important;
}
.hovereffect:hover {
  background-color: #cbc3e3;
}
.borderShedow {
  box-shadow: 10px 5px 5px white;

  border-radius: 20px;
  display: flex;
  justify-self: center;
  align-items: center;
  text-align: center;
  padding: 35px;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 0.4);
  margin-top: 20px;
  margin-bottom: 10px;
  overflow: hidden;
}

/* .slick-slide {
  margin: 0 2px;
} */

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.fs {
  font-size: 14px;
}

.datetime-picker-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datetime-picker {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}

.flexbox {
  display: "flex" !important;
  margin-top: 10 !important;
  width: "100%";
  padding: "5px";
  flex-direction: "row";
  justify-content: "space-between";
  align-items: "center";
  background-color: "green" !important;
  color: "red" !important;
}

.card-body {
  width: auto;
  height: auto;
  min-height: 1px;
  padding: 20 !important;
  position: relative;
}
.view-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* body */
* {
  font-family: "Rubik" !important;
}

/* signin  */
.textfield {
  border: 1px solid #d9d9d9 !important;
  border-radius: 12px !important;
  width: 400 !important;
  height: 54px !important;
  background-color: #d9d9d9 !important;
}
.box-container-form {
  display: flex;
  flex-direction: column;
}
.brline {
  display: flex;
  flex-direction: row;
}
.brline:before,
.brline:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #d9d9d9;
  margin: auto;
}
.cards {
  display: flex;
  justify-content: space-around;
  background-color: whitesmoke;
  padding: 1.2rem;
  border-radius: 10px;
  margin-bottom: 10px;
}
.tabbox {
  /* background: rgba(203, 195, 227, 0.5) !important; */
  border-radius: 20px !important;
  margin-right: 20px;
  width: 33% !important;
}
.hovereffect {
  padding: 5px !important;
}
.hovereffect:hover {
  background-color: #cbc3e3;
}
.borderShedow {
  box-shadow: 10px 5px 5px white;

  border-radius: 20px;
  display: flex;
  justify-self: center;
  align-items: center;
  text-align: center;
  padding: 35px 16px;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 0.4);
  margin-top: 20px;
  margin-bottom: 10px;
}

/* .slick-slide {
  margin: 0 2px;
} */

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  overflow: hidden;
  margin: 0 -10px;
}

.fs {
  font-size: 14px;
}

.boxcon {
  display: flex;
  justify-content: space-between;
}

.button {
  border: 1px solid black;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px;
}

.button:focus {
  border: 1px solid black;
  background-color: #663082;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
}
.buttonclick {
  border: 1px solid black;
  background-color: #663082;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
}
.listBox {
  border: 1px solid #663082;
  border-radius: 10px;
  padding: 30px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  margin-top: 10px;
}
.listBox div{
  margin: 1rem 2rem;
}

.slider-car-image {
  max-width: 100px;
  margin: auto;
}

@media (max-width: 998px) {
  .listBox {
    display: block;
  }
  .listBox div{
    margin: 0rem 1rem;
  }
}