
footer{
    background: linear-gradient(31deg, #6f35c7 0%, #9228cd 100%) 0% 0% no-repeat
    padding-box !important;
    color: white;
  }
  
  footer .footer-wrap {
    padding: 50px 0px;
  }
  
  footer .footer-wrap h6 {
    padding: 20px 0px;
    font-weight: 900;
  }
  
  footer .footer-wrap .about  {
    width: 100%;

  }
  
  footer .footer-wrap .social-media {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  footer .footer-wrap .social-media a .MuiSvgIcon-root{
    font-size: 2rem;
  }
  
  footer .footer-wrap .social-media a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  footer .footer-wrap a{
    color: #d7c6c6;
    text-decoration: none;
  }
  footer .footer-wrap .company a:hover, footer .footer-wrap .useful-links a:hover {
    color: #efeded;
  }
  
  footer .footer-wrap .company p, footer .footer-wrap .useful-links p {
    margin-bottom: 0.3em;
  }
  



  @media (min-width: 768px) {
    footer .footer-wrap .social-media {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    
      }
    footer {
      text-align: left !important;
    }
    footer .footer-wrap {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-around;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 30px;
    }
    footer .footer-wrap .about {
      width: 40%;
    }
  }
  
  
  
  @media (min-width: 1200px) {
    footer .footer-wrap .social-media {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
    
      }

    footer .footer-wrap .about {
      width: 20%;
    }
  }  