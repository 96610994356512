.carSection{
    padding: 7rem;
    background: #E6E6E66B;
}
.carHeading{
    font-size: 2.4rem;
    color: #2B2B2B;
    font-weight: bold;
}
.carHeading1{
    font-size: 1rem;
}

.carCards{
    box-shadow: 1px 4px 40px #0000000D;
border-radius: 37px;
height: 100%;
border: none !important;
}
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}


.carImg{
    max-width: 75%;
    height: auto;
    text-align: center;
    padding: 2rem 1rem;
}

.carDataHead{
    color: #151515;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.6rem;
}
.carSeats{
    color: #4f4e4e;
    font-size: 1.2rem;
    margin-bottom: 1rem;

}
.carDescription{
    color: #4f4e4e;
    font-size: .9rem;
    margin-bottom: 1rem;
    line-height: 1.2rem;
}

@media (max-width: 768px) {
    .carSection{
        padding: 3rem;
        background: #E6E6E66B;
    }
    .carCards{
    height: 100%;
    display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
    }
    .grid{
        display: flex;
        flex-direction: column;
    }

  }

  @media (max-width: 653px) {
    .carSection{
        padding: 1rem;
    }
}
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .carCards{
        height: 100%;
        display: flex;
      flex-direction: column;
      flex-grow: 1;
        }
        .grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .carImg{
            max-width: 60%;
            height: auto;
            text-align: center;
            padding: .8rem;
        }
        
        .carDataHead{
            color: #151515;
            font-weight: bold;
            margin-bottom: 0;
            font-size: 1rem;
        }
        .carSeats{
            color: #4f4e4e;
            font-size: 0.8rem;
            margin-bottom: 1rem;
        
        }
        .carDescription{
            color: #4f4e4e;
            font-size: .9rem;
            margin-bottom: 1rem;
            line-height: 1.2rem;
        }
  }

