.cardContainer {
  padding: 2rem 3rem;
  border-radius: 35px;
  width: 50%;
  margin: 3rem auto;
}
.heading {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.formText {
  background: #f5f6fa 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  border: none !important;
}
.formText:hover {
  border: none !important;
}

@media (max-width: 768px) {
  .cardContainer {
    padding: 1rem 1rem;
    border-radius: 35px;
    width: 95%;
    margin: 3rem auto;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {

}