
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.533); /* Adjust opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

.payment-loader {

  padding: 20px;
  border-radius: 10px;
  text-align: center;
    }
    
    .payment-loader .binding {
      content : '';
      width : 60px;
      height : 4px;
      border : 2px solid #fff;
      margin : 0 auto;
    }
    
    .payment-loader .pad {
      width : 75%;
      height : 100%;
      border-radius : 8px;
      border : 2px solid #fff;
      padding : 6px;
      margin : 0 auto;
    }
    
    .payment-loader .chip {
      width : 12px;
      height: 8px;
      background: #fff;
      border-radius: 3px;
      margin-top: 4px;
      margin-left: 3px;
    }
    
    .payment-loader .line {
      width : 52px;
      margin-top : 6px;
      margin-left : 3px;
      height : 4px;
      background: #fff;
      border-radius: 100px;
      opacity : 0;
      -webkit-animation : writeline 3s infinite ease-in;
      -moz-animation : writeline 3s infinite ease-in;
      -o-animation : writeline 3s infinite ease-in;
      animation : writeline 3s infinite ease-in;
    }
    
    .payment-loader .line2 {
      width : 32px;
      margin-top : 6px;
      margin-left : 3px;
      height : 4px;
      background: #fff;
      border-radius: 100px;
      opacity : 0;
      -webkit-animation : writeline2 3s infinite ease-in;
      -moz-animation : writeline2 3s infinite ease-in;
      -o-animation : writeline2 3s infinite ease-in;
      animation : writeline2 3s infinite ease-in;
    }
    
    .payment-loader .line:first-child {
      margin-top : 0;
    }
    
    .payment-loader .line.line1 {
      -webkit-animation-delay: 0s;
      -moz-animation-delay: 0s;
      -o-animation-delay: 0s;
      animation-delay: 0s;
    }
    
    .payment-loader .line.line2 {
      -webkit-animation-delay: 0.5s;
      -moz-animation-delay: 0.5s;
      -o-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
    
    .payment-loader .loader-text {
      text-align : center;
      margin-top : 20px;
      font-size : 16px;
      line-height: 16px;
      color : #fff;
      font-weight: bold;
    }
    
    
    @keyframes writeline {
      0% { width : 0px; opacity: 0; }
      33% { width : 52px; opacity : 1; }
      70% { opacity : 1; }
      100% {opacity : 0; }
    }
    
    @keyframes writeline2 {
      0% { width : 0px; opacity: 0; }
      33% { width : 32px; opacity : 1; }
      70% { opacity : 1; }
      100% {opacity : 0; }
    }
    