.downloadSection{
    padding: 7rem;
}
.appImg{
    width: 75%;
    height: auto;
}
.appleDownload{
    width: 30%;
    height: auto;
}
.googleDownload{
    width: 30%;
    height: auto;
}
.downloadTextContainer{
    padding: 0 2rem;
}
.heading1{
    font-size: 1.8rem;
    margin-bottom: 0;
}
.heading2{
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 3rem;
}
.description{
    padding-right: 3rem;
}

@media (max-width: 768px) {
    .downloadSection{
        padding: 3rem;
    }
    .appleDownload{
        width: 45%;
        height: auto;
    }
    .googleDownload{
        width: 45%;
        height: auto;
    }
    .downloadBtn{
        margin-bottom: 2rem !important;

    }
  }
  @media (max-width: 653px) {
    .downloadSection{
        padding: 1rem;
    }
}
  @media (min-width: 768px) and (max-width: 991.98px) {
    .downloadSection{
        padding: 3rem;
    }
    .appleDownload{
        width: 45%;
        height: auto;
    }
    .googleDownload{
        width: 45%;
        height: auto;
    }
    .downloadTextContainer{
        padding: 0;
    }
  
  }