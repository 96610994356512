.privacySection{
    padding: 3rem 12rem;
}
.privacyHeading{
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 3rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
}
.description{
    line-height: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold !important;
}
.h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight:  bold !important;
}
.subDescription {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.subHeading {
    font-family: 'Montserrat', sans-serif;
}
@media (max-width: 768px) {
    .privacySection{
        padding: 3rem 3rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
  
  }