.container {
  background: url('../../shared/images/heroImg.png') no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 5.5rem 0;
  display: flex;
  flex-grow: 1;
  /* flex-direction: column; */
  flex-direction: row-reverse;
  align-items: center;
}

.heading1 {
  font-size: 2.5rem;
  text-align: start;
  letter-spacing: 0px;
}

.heading2 {
  font-size: 1rem;
  text-align: start;
  line-height: 1.5rem;
}
.cardContainer {
  margin: 0 7rem;
  padding: 2rem 3rem;
  border-radius: 59px;
  text-align: center;
}

.textContainer{
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
  /* margin: auto; */
  /* remove later */
  width: 60%;
}
.heroGrid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}


@media (max-width: 768px) {
  .container{
    flex-direction: row;

  }
  
  .cardContainer {
    margin: 0 1rem;
    padding: 2rem .5rem;
  }
  .textContainer{
    padding-left: 2rem;
    padding-right: 2rem;
    /* margin-bottom:3rem; */
    width: 100% !important; 
  }
  .heroGrid{
    grid-template-columns: none;
  }
}



@media (min-width: 768px) and (max-width: 1200px) {
  .container{
    flex-direction: row;

  }
  .textContainer{
    padding-left: 5rem;
    padding-right: 5rem;
    /* margin-bottom:3rem; */
    width: 100%;
  }
  .heroGrid{
    grid-template-columns: none;
  }
  .cardContainer {
    margin: 0 2rem;
    padding: 1rem;
  }
}