.cars-heading{
  font-size: 1.2rem;
  font-weight: 600;
  color: #151515;
  line-height: 1rem;
}
.cars-seats{
    font-size: .9rem;
    font-weight: 600;
    color: gray;
}
.cars-fare{
    line-height: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #151515;
}
.car-img{
    width: 100%;
}
.cars-card{
    border: none !important;
    border-radius: 19px;
    cursor: pointer;
}

.cars-radio-btn{
    
    cursor: pointer;
    box-shadow: none !important;
    border: .99px solid gray !important;
}

.cars-radio-btn:checked{
    background-color: #6f35c7 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

@media (max-width: 768px) {

  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
  
  }