.heading {
  font-size: 1.5rem;
  font-weight: bold;
}
.searchLogo {
  width: 50%;
  height: auto;
}
.formLabel {
  color: #f59523 !important;
  font-size: 1rem !important;
  margin: 0 !important;
}
.formLabelDark {
  color: #181818 !important;
  font-size: 1rem !important;
  margin: 0 !important;
}
.formText {
  background: #f5f6fa 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  border: none !important;
}
.formText:hover {
  border: none !important;
}
.dateTime {
  background: #f5f6fa 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
}

.placesList {
  cursor: pointer;
  padding: 1rem;
  font-size: .9rem;
  text-align: start;
}
.placesList:hover {
  background-color: #f8f7f7;
}
.autocompleteContainer {
  position: relative;
}

.autocompleteDropdown {
  position: absolute;
  width: auto;
  box-sizing: border-box;
  z-index: 1000;
}

