.imgLogo{
    width: 45%;
    height: auto;
}

.navbarBg{
    background-color: #fff !important;
    color: #9228CD !important;
}

.navLinks{
    color: #353535 !important;
    font-weight: 500;
    margin-right: 1rem;
}
.navLinks:hover{
    color: #6F35C7 !important;
    font-weight: 500;
}
.navButton{
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .marginSmBottom{
        margin-bottom: .5rem !important;
    }
  }
  @media (max-width: 653px) {
    .navbar-brand{
        padding-left: 0 !important;
    }
    
}
  
@media (min-width: 768px) and (max-width: 1200px) {
    .navLinks{
       font-size: .6rem !important;
    }
    .navButton{
        font-size: .6rem !important;
    }
  }